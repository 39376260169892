import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Link as RouterLink } from 'react-router-dom'
import {
  ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from './ApercuPro.jsx'

const WorkItemVid = ({section, elemento, description, work}) => {

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Link color="inherit"
        aria-label="menu"
        component={RouterLink}
        to={section}>
        <ReactPlayer
            url= {elemento}
            className='react-player'          
            playing
            muted
            loop
            width='100%'
            height='100%'
            playsinline
            />
            <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
              <Typography               
                className="c-img"
                component="div"              
                justifyContent="center"
                color="white"
                variant="caption"
                >
                <Box 
                  className="txt" 
                  sx={{ textAlign: 'center', mb: 2 }}                
                  >
                  {description}
                </Box>
              </Typography>
            </ThemeProvider>
      </Link>
    </Grid>
  )
}

WorkItemVid.propTypes = {}

export default WorkItemVid

