import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import {
  ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from './ApercuPro.jsx'

const PresInfo = props => {
  return (
    <Grid
        container
        justifyContent="flex-start"
        alignItems="stretch"
        className='marginInfo'
        >
        <ThemeProvider theme={ApercuPro}>
          <CssBaseline />
          <Typography component="div" variant='h6'>
            <Box sx={{ lineHeight: 1, m: 0.5 }}>Madrid, Spain</Box>
            <Box sx={{ lineHeight: 1, m: 0.5 }}>+34 617288834</Box>
            <Box sx={{ lineHeight: 1, m: 0.5 }}>ivanzimmermann@gmail.com</Box>
          </Typography>
        </ThemeProvider>
    </Grid>
  )
}

PresInfo.propTypes = {}

export default PresInfo