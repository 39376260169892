import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import {
    ThemeProvider,
  } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SiBehance, SiInstagram } from 'react-icons/si';
import { TiSocialLinkedin} from 'react-icons/ti';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from './ApercuPro.jsx'
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';


const PresHeader = props => {
  return (
    <Grid 
        container 
        spacing={3}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        >
        <Grid item xs={8} md={6}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='body2'>Independient Creative Director [AD]</Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={4} md={6}>
            <Stack 
                direction="row" 
                alignItems="center" 
                justifyContent="flex-start"
                spacing={2}>
                <IconButton 
                    disableRipple 
                    aria-label="Behance" 
                    size="small" 
                    href='https://www.behance.net/ivanzim?tracking_source=search_users|ivan%20zimmermann'
                    target="_blank" 
                    rel="noopener">
                    <SiBehance color="black" fontSize="20"/>
                </IconButton>
                <IconButton 
                    disableRipple 
                    aria-label="Instagram" 
                    size="small"
                    href='https://www.instagram.com/ivanzimmmm/?hl=es'
                    target="_blank" 
                    rel="noopener">
                    <SiInstagram color="black"  fontSize="20"/>
                </IconButton>
                <IconButton 
                    disableRipple 
                    aria-label="Linked" 
                    size="small"
                    href='https://ar.linkedin.com/in/ivan-zimmermann'
                    target="_blank" 
                    rel="noopener">
                    <TiSocialLinkedin  color="black" fontSize="25"/>
                </IconButton>
            </Stack>
        </Grid>
    </Grid>        
    
  )
}

PresHeader.propTypes = {}

export default PresHeader