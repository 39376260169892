import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Imagenes from './../Imagenes'

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`
const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  

const WorkBudwieser = props => {
  return (
    <Grid p={3}
    container
    direction="column"
    justifyContent="center"
    >
    <Grid container
    direction="row"
    justifyContent="center"
    alignItems="center">
        <Link color="inherit"
            aria-label="menu"
            component={RouterLink}
            to={'/works'}>
            <RiGridFill fontSize={30} />
        </Link>
    </Grid>
    <Grid container pt={3}>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Typography variant='h4'>
                Budweiser: We brew birra
            </Typography>
            <Typography variant='h5' py={3}>
                When Budweiser used Barry White to sing about their boring RTBs.
            </Typography>
            <Typography variant='body2' pb={1}>
                Budweiser needed to relaunch their brand in Argentina and wanted to talk about their unique elaboration process. With the rise of craft beers in the local market, we decided to explain why they are different – and created a concept by mixing something technical like “Brew” with something very Argentine, like “Birra”, (a word used to refer to beer in a colloquial way), all with the voice of Barry White attesting that “We don't make beer, we brew it.”
            </Typography>               
            <Typography variant='body2'>
                Creative Agency: AFRICA / Ivan Zimmermann (Freelance Creative)
            </Typography>
            <Typography variant='body2'pb={3}>
                Voice Over: Seba Costa
            </Typography>
        </ThemeProvider>
    </Grid>
    <Grid className='player-wrapper' pb={2}>
        <ReactPlayer
            url= 'https://vimeo.com/482718562'
            className='react-playeri'          
            controls
            width='100%'
            height='100%'
            />
    </Grid>
    <Grid className='player-wrapper' pb={2}>
        <ReactPlayer
            url= 'https://vimeo.com/482716990'
            className='react-playeri'          
            controls
            width='100%'
            height='100%'
            />
    </Grid>
    <Grid className='player-wrapper' pb={2}>
        <ReactPlayer
            url= 'https://vimeo.com/482717773'
            className='react-playeri'          
            controls
            width='100%'
            height='100%'
            />
    </Grid>
    <Grid container
    direction="row"
    justifyContent="center"
    alignItems="center"
    spacing={1}
    pt={1}
    >
        <Grid item xs={12} sm={6}>
            <Img alt="ivan zimmermann" src={Imagenes.bud2}/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Img alt="ivan zimmermann" src={Imagenes.bud3}/>
        </Grid>
    </Grid>
    <Grid container
    direction="row"
    justifyContent="center"
    alignItems="center"
    pt={1}
    >
        <Grid item xs={12} py={1}>
            <Img alt="ivan zimmermann" src={Imagenes.bud5}/>
        </Grid>
    </Grid>
    <ThemeProvider theme={ApercuPro}>
        <CssBaseline />
        <Box pt={3}>
            <Stack 
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}>
                    <StyledLink 
                        textTransform="none" 
                        lineHeight= {1} 
                        variant="body2" 
                        href="https://www.adlatina.com/publicidad/una-campaña-en-la-argentina-creada-en-brasil-por-una-agencia-llamada-africa-y-con-un-eslogan-que-mezcla-inglés-y-lunfardo" 
                        underline="hover" 
                        target="_blank" 
                        rel="noopener">
                    {'https://www.adlatina.com/publicidad/una-campaña-en-la-argentina-creada-en-brasil-por-una-agencia-llamada-africa-y-con-un-eslogan-que-mezcla-inglés-y-lunfardo'}
                    </StyledLink>
                    <StyledLink 
                        textTransform="none" 
                        lineHeight= {1} 
                        variant="body2" 
                        href="https://www.dossiernet.com.ar/articulo/we-brew-birra-la-campana-de-africa-argentina-para-budweiser/18636" 
                        underline="hover" 
                        target="_blank" 
                        rel="noopener">
                    {'https://www.dossiernet.com.ar/articulo/we-brew-birra-la-campana-de-africa-argentina-para-budweiser/18636'}
                    </StyledLink>
                    <StyledLink 
                        textTransform="none" 
                        lineHeight= {1} 
                        variant="body2" 
                        href="http://www.latinspots.com/sp/noticia/africa-argentina-crea-su-primer-trabajo-para-budweiser/49741" 
                        underline="hover" 
                        target="_blank" 
                        rel="noopener">
                    {'http://www.latinspots.com/sp/noticia/africa-argentina-crea-su-primer-trabajo-para-budweiser/49741'}
                    </StyledLink>
            </Stack>
        </Box>
    </ThemeProvider>
</Grid>
)
}

WorkBudwieser.propTypes = {}

export default WorkBudwieser