import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'

const WorkBecker= props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Becker: A beer won’t change the world
                </Typography>
                <Typography variant='h5' py={3}>
                    When Becker spoofed today’s advertising.
                </Typography>
                <Typography variant='body2' pb={1}>
                    Let's talk about brands with purpose. The purpose of this beer is not becoming a boring beer making boring advertising manifestos.
                </Typography>               
                <Typography variant='body2'>
                    Creative Agency: Tr3s / Ivan Zimmermann (Freelance Creative Director)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: Poster / Dir. Plástico
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/703627979'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/703627947'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
    </Grid>
  )
}

WorkBecker.propTypes = {}

export default WorkBecker