import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Presentation from './Presentation'

import { Button } from '@mui/material';
import { FaArrowUp } from 'react-icons/fa'
import { styled } from '@mui/material/styles';
import { animateScroll as scroll} from 'react-scroll'

const Fab = styled(Button)(({ theme }) => ({
    position: 'fixed', 
    bottom: 30, 
    right: '10%',
    backgroundColor: 'black', 
    width: 60, 
    height: 60, 
    borderRadius: 100, 
    justifyContent: 'center',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
}));

const AppFrame = ({children }) => {

    const [visible, setVisible] = useState(false)

    const FabVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if ((scrolled > 700) && (window.screen.width < 600)) {
        setVisible(true)
      }
      else {
        setVisible(false)
      }
    }
  
    window.addEventListener('scroll', FabVisible);

    return (
        <Grid 
            container
            justify="center">
            <Grid 
                container 
                spacing={2}
                className='flex-section'
                >
                <Grid item 
                    xs={12}
                    md={6}         
                    className='flex-col-scroll'
                    >
                    <Presentation/>
                </Grid>
                <Grid id='appframe' 
                    item                
                    xs={12}
                    md={6}
                    className='flex-col-scroll'  
                    >
                    {children}
                    <Fab onClick={() => scroll.scrollToTop()}
                        style={{display: visible ? 'inline' : 'none'}} >
                        <FaArrowUp/>
                    </Fab>
                </Grid>
            </Grid>
        </Grid>
    )
}

AppFrame.propTypes = {
    children: PropTypes.node
}

export default AppFrame