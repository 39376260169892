import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const WorkViltar = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid item xs={12} pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Viltar: Penguin
                </Typography>
                <Typography variant='h5' py={3}>
                    When a giant penguin walked through LA city.
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={1}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                No penguins were injured in the following commercial.
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={3}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                Creative Agency: Dhélet VMLY&R / Ivan Zimmermann (Creative Director)
            </Typography>
            <Typography variant='body2'>
                Production Company: Ladoble / Dir. Ivan Vescovo
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/485665461'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/486141667'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {''}
                        </StyledLink>              
                </Stack>
            </Box>
        </ThemeProvider>
    </Grid>
  )
}

WorkViltar.propTypes = {}

export default WorkViltar