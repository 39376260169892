import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import { styled } from '@mui/material/styles';
import Imagenes from './../Imagenes'

const StyledLink = styled(Link)`
    color: inherit;
    &:hover {
    background: black;
    color: white;
    };
    `
const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    });

const WorkCocaColaNS = props => {
  return (
    <Grid p={3}
    container
    direction="column"
    justifyContent="center"
    >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Coca-Cola No Sugar: Enjoy the taste of No
                </Typography>
                <Typography variant='h5' py={3}>
                    When a Coca-Cola campaign became a global case study.
                </Typography>
                <Typography variant='body2' pb={1}>
                    Imagine pitching for a global Coca-Cola campaign and winning it. Then the unexpected. Yes covid-19. Nobody knew what was going on. Brands were talking about stopping investing in advertising. And you go from shooting a campaign in 5 countrys, to zero. Anyway, we found a solution. We did the whole campaign with Coca-Cola footage (if there is a brand in the world with almost unlimited access to footage, it's Coca-Cola) and a little use of filmsupply.com. We rotoscoped the bottles with the No-Sugar tags. And it became a worldwide case study within Coca-Cola on how to produce footage without being able to go out and shoot it. Too bad, I love to travel. But you learn something new every day.
                </Typography>               
                <Typography variant='body2'>
                    Creative Agency: Mercado Mccann / Ivan Zimmermann (Freelance Creative)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: Landia / Dir. Lucas Shannon
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/769427894'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        pt={1}
        >
            <Grid item xs={12} pb={2}>
                <Img alt="Coca Cola No Sugar advertising" src={Imagenes.ccnsmosaic}/>
            </Grid>
            <Grid item xs={12} pb={2}>
                <Img alt="Coca Cola No Sugar advertising" src={Imagenes.ccnsfriendzone}/>
            </Grid>
            <Grid item xs={12} pb={2}>
                <Img alt="Coca Cola No Sugar advertising" src={Imagenes.ccnsmosaicpost}/>
            </Grid>
        </Grid>
    </Grid>
    )
}

WorkCocaColaNS.propTypes = {}

export default WorkCocaColaNS