import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const WorkYpf = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid item xs={12} pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    YPF Elaion Auro: Careful car owners
                </Typography>
                <Typography variant='h5' py={3}>
                    When YPF made you remember the worst part of having a car.
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={1}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                For the launch of YPF's premium lubricant, the challenge was to find an insight, a consumer truth that most drivers could identify with. But not to every car owner, just the ones who trully loves their cars. With something as difficult to show as the internal care of the car: its engine.
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={3}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                Creative Agency: ISLA Buenos Aires / Ivan Zimmermann (Freelance Creative Director)
            </Typography>
            <Typography variant='body2'>
                Production Company: Agosto - Labhouse / Dir. Luisa Kracht
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' >
            <ReactPlayer
                url= 'https://vimeo.com/819239888'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.adlatina.com/publicidad/preestreno-isla-buenos-aires-e-ypf-brindan-mayor-cuidado-a-los-motores" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.adlatina.com/publicidad/preestreno-isla-buenos-aires-e-ypf-brindan-mayor-cuidado-a-los-motores'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticia/isla-buenos-aires-e-ypf-desarrollan-el-lanzamiento-de-elaion-auro-/61675" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticia/isla-buenos-aires-e-ypf-desarrollan-el-lanzamiento-de-elaion-auro-/61675'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://dossiernet.com.ar/articulo/isla-buenos-aires-e-ypf-lanzan-nueva-campana/28393" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://dossiernet.com.ar/articulo/isla-buenos-aires-e-ypf-lanzan-nueva-campana/28393'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
    </Grid>
  )
}

WorkYpf.propTypes = {}

export default WorkYpf