import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const WorkToro = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid item xs={12} pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Toro Wine: Paddlers
                </Typography>
                <Typography variant='h5' py={3}>
                    When a low-price wine talked to its true audience for the first time.
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={1}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                A boxed wine, mixed with soda and served in a pint glass. Not very sexy for an advertisement. But we decided to rescue its essence: a wine consumed mainly by workers. With a campaign that positioned this wine as an emblem of the Argentine working-class table, speaking directly to those who have to try twice as hard. A wine made by “laburantes” for “laburantes” (an Argentine word for tough workers).
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={3}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                Creative Agency: Leo Burnett Buenos Aires / Ivan Zimmermann (Freelance Creative)
            </Typography>
            <Typography variant='body2'>
                Production Company: Rebolución / Dir. Marcelo Burgos
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' >
            <ReactPlayer
                url= 'https://vimeo.com/515455746'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.adlatina.com/articulo.php?slug=/publicidad/“remadores”-lo-nuevo-de-leo-burnett-para-vino-toro" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.adlatina.com/articulo.php?slug=/publicidad/“remadores”-lo-nuevo-de-leo-burnett-para-vino-toro'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticia/remadores-lo-nuevo-de-rebolucion-para-toro/48297" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticia/remadores-lo-nuevo-de-rebolucion-para-toro/48297'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.dossiernet.com.ar/articulo/vino-toro-y-leo-burnett-remadores-del-lapiz-de-oro/17637" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.dossiernet.com.ar/articulo/vino-toro-y-leo-burnett-remadores-del-lapiz-de-oro/17637'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://infonegocios.info/infopublicidad/vino-toro-relanza-su-marca-con-remadores-by-leo-burnett" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://infonegocios.info/infopublicidad/vino-toro-relanza-su-marca-con-remadores-by-leo-burnett'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.iprofesional.com/marketing/276922-empresa-plan-trabajo-Remadores-la-campana-de-vino-Toro-que-apela-al-espiritu-del-trabajador-argentino" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.iprofesional.com/marketing/276922-empresa-plan-trabajo-Remadores-la-campana-de-vino-Toro-que-apela-al-espiritu-del-trabajador-argentino'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
    </Grid>
  )
}

WorkToro.propTypes = {}

export default WorkToro