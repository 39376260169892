import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const WorkBonobon = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Bonobon: Advice
                </Typography>
                <Typography variant='h5' py={3}>
                    When a candy needed people to stop giving it away and actually eat it.
                </Typography>
                <Typography variant='body2' pb={1}>
                    An iconic chocolate for Argentines positioned as a candy to give as a gift instead of personal consumption. This campaign tries to reverse this behavior with arguments and advice from those who know the least about sharing: kids.
                </Typography>               
                <Typography variant='body2'>
                    Creative Agency: Leo Burnett Buenos Aires / Ivan Zimmermann (Creative Director)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: Hachiko / Dir. Maureen Hufnagel
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' >
            <ReactPlayer
                url= 'https://vimeo.com/485673498'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.dossiernet.com.ar/articulo/consejo-nueva-campana-de-leo-burnett-para-bon-o-bon/8292" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.dossiernet.com.ar/articulo/consejo-nueva-campana-de-leo-burnett-para-bon-o-bon/8292'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticia/leo-burnett-da-consejos-para-bon-o-bon/40621" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticia/leo-burnett-da-consejos-para-bon-o-bon/40621'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
    </Grid>
  )
}

WorkBonobon.propTypes = {}

export default WorkBonobon