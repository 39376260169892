import React, { useEffect }  from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from './ApercuPro.jsx'
import { Grid } from '@mui/material';
import Imagenes from './Imagenes'
import { styled } from '@mui/material/styles';

const Img = styled('img')(({ theme }) => ({
    margin: '0',
    padding: '0',
    display: 'block',
    maxWidth: '80%',
    maxHeight: '80%',
}));

const Recognitions = props => {

    useEffect(() => {
        const isMobile = window.innerWidth <= 768; // Ajusta el ancho según tus necesidades
    
        if (!isMobile) {
            const element = document.getElementById('recognitions');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }}, []);

  return (
    <Grid p={3} id='recognitions'>
        <Box sx={{ pt:6 }}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline /> 
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                     <Typography lineHeight={1.2} variant="h5" component="div" gutterBottom>
                        Nope. No Grand Prix with +100 copy/paste awards in this list (I'm still working on it). But you will see lots awards for many different brands and projects I worked on. You can check up on it.
                     </Typography> 
                    <Grid container pt={3} >       
                        <Grid item xs={4} md={1.5} pb={0.5}>
                            <Img alt="logo Cannes Lions" src={Imagenes.cannes}/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                                Cannes Lions - Silver - Outdoor / Olé “Memories”
                            </Typography>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                                Cannes Lions - Bronze - Print / Olé “Memories”
                            </Typography>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                                Cannes Lions - Bronze - Outdoor / Hepachofa “This is gonna get ugly”
                            </Typography>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                                Cannes Lions - Bronze - Print / Fecofar laxative “Escapes”
                            </Typography>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                                Cannes Lions - Shortlist - Media / AAD “Revealing light”
                            </Typography>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                                Cannes Lions - Shortlist - Outdoor / Gentleman “Socks and sandals”, “Mullet”
                            </Typography>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                                Cannes Lions - Shortlist - Outdoor / Gentleman “Mr. brainless”
                            </Typography>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                                Cannes Lions - Shortlist - Outdoor / ACADAP “Spaghetti”, “Rice”, “Pumpkin”
                            </Typography>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                                Cannes Lions - Shortlist - Tv / Farmacity “Shame”
                            </Typography>
                            <Typography variant="body2" lineHeight={1.5} borderBottom={1} mb={3}>
                                Cannes Young Lions Argentina 2010 - Tv finalist
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >    
                    <Grid item xs={3} md={1.5} pb={1}>
                        <Img alt="logo El Diente" src={Imagenes.diente}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Gold - Tv / Ab InBev "Peludo"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Gold - Tv / Coca-Cola "Naciste acá"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Silver - Print / Olé “Recuerdos”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Silver - Interactive / Warsteiner "Caballo"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Silver - Interactive real response / Warsteiner "Caballo"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Tv / Vino Toro "Remadores"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Interactive social media / Warsteiner "Caballo"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Interactive social video / Warsteiner "Caballo"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Activation & Promo / Fiat “Calles felices”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Tv / Movistar "Maniac"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Tv / Movistar "Bamboleo" 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Tv / Movistar "Commanche"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Outdoor / Olé “Recuerdos”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Radio / Tulipán “Concentración argentina”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>            
                            Diente (Arg Creative Circle) - Bronze - Interactive / Tulipán “El amigo”
                            </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Bronze - Radio / Olé “Conferencia de la lora” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Mention - Tv & other screens / Warsteiner "Caballo"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Diente (Arg Creative Circle) - Mention - Tv / Fiat “Calles felices”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1} mb={3}>
                            Diente (Arg Creative Circle) - Mention - Tv / Bon o bon “Consejo”
                        </Typography>
                    </Grid>
                </Grid>
                <Grid   container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                    <Grid item xs={4} md={2} pb={1}>
                        <Img alt="logo Effie" src={Imagenes.effie}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Effie Awards - Gold - Ab InBev "Peludo"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Effie Awards - Silver x2 - Ab InBev "Peludo"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Effie Awards - Gold - Movistar "Multiplicate"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>                
                            Effie Awards - Silver - Personal “Orgullosos de papá”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Effie Awards - Silver - VEA “Pensamientos”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Effie Awards - Silver - OLX “¿Cuánto vale?”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Effie Awards - Silver - Farmacity “La salud también se contagia” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Effie Awards - Silver - Movistar “Dobles”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Effie Awards - Silver - Directv “Y candela?” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1} mb={3}>
                            Effie Awards - Bronze - YPF Elaion Auro "Cuidadosos"
                        </Typography>
                    </Grid>
                </Grid>
                <Grid   container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                    <Grid item xs={3} md={1.5} pb={1}>
                        <Img alt="logo El Ojo" src={Imagenes.elojo}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Gold - Integrated campaign - Ab InBev “Peludo”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Gold - Tv - Ab InBev “Peludo”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Best Argentine Radio - Olé “Conferencia de la lora” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>         
                            El Ojo de Iberoamérica - Gold - Print / Playstation “Pills”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Silver - Interactive / Warsteiner "Horse"
                            </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Silver - Promo / Warsteiner "Horse"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Silver - Film / Warsteiner "Horse"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Silver - Film Craft / Warsteiner "Horse"
                            </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Silver - Tv / Vino toro "Remadores"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Silver - Tv / Movistar “Maniac”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Silver - Print / Fecofar “Lion” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Ojo de Iberoamérica - Bronze - Print / Fecofar “Bull”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1} mb={3}>
                            El Ojo de Iberoamérica - Bronze - Tv / Movistar “Maniac”
                        </Typography>
                    </Grid>
                </Grid>
                <Grid   container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                    <Grid item xs={2} md={1} pb={1}>
                        <Img alt="logo Fiap" src={Imagenes.fiap}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            FIAP - Gold - Tv / Movistar “Maniac”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            FIAP - Gold - Radio / Tulipán “Concentración argentina” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            FIAP - Silver - Radio / Tulipán “Calentones”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1} mb={3}>
                            FIAP - Silver - Radio / Olé “Conferencia de la lora”
                        </Typography>
                    </Grid>
                </Grid>
                <Grid   container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                    <Grid item xs={2} md={1} pb={1}>
                        <Img alt="logo Premio El Sol" src={Imagenes.sol}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            El Sol (Spain) - Silver - Tv / Farmacity “Verguenza” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1} mb={3}>
                            El Sol (Spain) - Bronze - Print / Olé “Recuerdos” 
                        </Typography>
                    </Grid>
                </Grid>
                <Grid   container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                    <Grid item xs={4} md={2} pb={1}>
                        <Img alt="logo Festival Wave" src={Imagenes.wave}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Wave (Brazil) - Gold - Print / Olé “Memories”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Wave (Brazil) - Silver - Radio / Olé “Concentración argentina” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1} mb={3}>
                            Wave (Brazil) - Bronze - Radio / Olé “Ascensorista hot” 
                        </Typography>
                    </Grid>
                </Grid>
                <Grid   container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                    <Grid item xs={3} md={1.3} pb={1}>
                        <Img alt="logo El Lapiz" src={Imagenes.lapiz}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Tv / Ab InBev “Peludo” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Silver - Tv / Alma Mora “Nunca dejes de creer” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Tv / YPF Elaion Auro “Cuidadosos” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Tv / Banco Nación "Guardias" 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Silver - Tv / Banco Nación "Independizate" 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Platinum Pencil Radio 2016 / Fiat “Gigoló” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Platinum Pencil Radio 2009 / Olé “Conferencia de la lora”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Tv / Coca-Cola “Naciste acá” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Tv / Vino toro "Remadores"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Tv / Fiat “Calles felices”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Radio / Fiat “Gigoló”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Radio / Cintabú sexshop “Por Olga” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Tv / Farmacity “Verguenza”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Tv / Farmacity “Pretendiente”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Radio / Tulipán “Tempomaré”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Outdoor / Tulipán “Corpiño”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Radio / Olé “Conferencia de la lora” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Print / Nicotinell “Globos” 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Lápiz de Platino - Gold - Tv / Alma Mora "Nunca dejes de creer" 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1} mb={3}>
                            Lápiz de Platino - Gold - Tv / YPF Elaion Auto "Cuidadosos" 
                        </Typography>
                    </Grid>
                </Grid>
                <Grid   container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                    <Grid item xs={3} md={1.5} pb={0.5}>
                        <Img alt="logo El Lapiz" src={Imagenes.lurzer}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Luerzer’s Archive book - Print / Nugget “UFO”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1} mb={3}>
                            Luerzer’s Archive book - Print / Playstation “Pills”
                        </Typography>
                    </Grid>
                </Grid>
                <Grid   container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                    <Grid item xs={12} md={12}>
                        <Typography variant="body2" >
                            OTHER AWARDS
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Martin Fierro Publicidad 2017 - Tv / Fiat “Calles felices”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>                
                            Martin Fierro Publicidad 2018 - Tv / Coca-Cola “Naciste acá”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>                
                            The Gunn Report - Tv / Farmacity “Shame”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Adweek Ad of the day / Warsteiner "Horse"
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Cream awards - Outdoor / Fecofar laxative "Lion, bull" 
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            IAB - Gold / Tulipán “El amigo”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Bestadsontv editors pick “Tulipán bra”
                        </Typography>
                        <Typography variant="body2" lineHeight={1.5} borderBottom={1}>
                            Bestadsontv editors pick “Nugget shoe polish UFO”
                        </Typography>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Box>
    </Grid>
  )
}

Recognitions.propTypes = {}

export default Recognitions