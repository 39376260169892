import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const WorkCocaColaMarta = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Coca-Cola: M.A.R.T.H.A. vs Robots
                </Typography>
                <Typography variant='h5' py={3}>
                    When Coca-Cola used Terminator and Robocop for a tv campaign.
                </Typography>
                <Typography variant='body2' pb={1}>
                    M.A.R.T.H.A. is a robot that was created to challenge everyone to distinguish between sugar-free Coke and original Coke. This second campaign goes a step further and challenges two of the most iconic robots in popular culture of the 80’s, Terminator and Robocop, to do the same.
                </Typography>               
                <Typography variant='body2'>
                    Creative Agency: GREY Argentina / Ivan Zimmermann (Freelance Creative)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: PRIMO / Dir. Felipe
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/515448917'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/515449303'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.adlatina.com/publicidad/preestreno:-grey-argentina-y-coca-cola-logran-que-marta-desaf%C3%ADe-a-robocop-y-terminator-t-800" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.adlatina.com/publicidad/preestreno:-grey-argentina-y-coca-cola-logran-que-marta-desaf%C3%ADe-a-robocop-y-terminator-t-800'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticia/m.a.r.t.a.-desafa-a-los-famosos-robocop-y-terminator-con-grey-argentina-y-primo/51468" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticia/m.a.r.t.a.-desafa-a-los-famosos-robocop-y-terminator-con-grey-argentina-y-primo/51468'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.sitemarca.com/coca-cola-sigue-la-saga-de-los-robots-m-a-r-t-a-desafia-a-robocop-y-terminator/" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.sitemarca.com/coca-cola-sigue-la-saga-de-los-robots-m-a-r-t-a-desafia-a-robocop-y-terminator/'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
    </Grid>
  )
}

WorkCocaColaMarta.propTypes = {}

export default WorkCocaColaMarta