import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {
  ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from './ApercuPro.jsx'

const WorkItemImg = ({section, elemento, description, work}) => {
  
  const Img = styled('img')({
    width: '100%',
    height: '100%', 
  });

  return (
    <Grid container alignItems="center" justifyContent="center">
        <Link color="inherit"
            aria-label="menu"
            component={RouterLink}
            to={section}>
            <Img alt={description} src={elemento} />
            <ThemeProvider theme={ApercuPro}>
              <CssBaseline />
              <Typography 
                variant="caption"
                className="c-img"
                component="div"              
                justifyContent="center"
                color="white"
                >
                <Box 
                  className="txt" 
                  sx={{ textAlign: 'center', mb: 2 }}                
                  >
                  {description}
                </Box>
              </Typography>
            </ThemeProvider>
        </Link>
    </Grid>
  )
}

WorkItemImg.propTypes = {}

export default WorkItemImg