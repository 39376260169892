import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Imagenes from './../Imagenes'

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const WorkFiat = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container 
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid item xs={12} pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Fiat: Happy Streets
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pt={3} pb={1}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                Road rage in Argentina is an issue. And since many of the streets in Buenos Aires are named after battles, we thought maybe that's why driving here sometimes becomes a war. What would happen if we changed those street names to the most beloved characters on television? What if we presented it to Congress as a bill?
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={3}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                Creative Agency: Leo Burnett Buenos Aires / Ivan Zimmermann (Creative Director)
            </Typography>
            <Typography variant='body2'>
                Production Company: Primo / Dir. Luisa Kracht
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' >
            <ReactPlayer
                url= 'https://vimeo.com/515453184'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            pt={1}
            >
            <Grid item xs={12}>
                <Img alt="ivan zimmermann" src={Imagenes.fiat2}/>
            </Grid>
        </Grid>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.adlatina.com/articulo.php?slug=/publicidad/“calles-felices”-preestreno-de-leo-burnett-argentina-para-fiat" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.adlatina.com/articulo.php?slug=/publicidad/“calles-felices”-preestreno-de-leo-burnett-argentina-para-fiat'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticia/fiat-y-leo-burnett-argentina-proponen-crear-calles-felices/40271" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticia/fiat-y-leo-burnett-argentina-proponen-crear-calles-felices/40271'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.dossiernet.com.ar/articulo/calles-felices-le-da-a-fiat-y-leo-burnett-el-lapiz-de-oro-de-mayo/8312" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.dossiernet.com.ar/articulo/calles-felices-le-da-a-fiat-y-leo-burnett-el-lapiz-de-oro-de-mayo/8312'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://insiderlatam.com/calles-felices-de-leo-burnett-para-fiat/" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://insiderlatam.com/calles-felices-de-leo-burnett-para-fiat/'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.totalmedios.com/nota/31972/calles-felices-gano-el-mf-como-mejor-comercial-del-2016" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.totalmedios.com/nota/31972/calles-felices-gano-el-mf-como-mejor-comercial-del-2016'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://infonegocios.info/infopublicidad/el-martin-fierro-a-la-mejor-publicidad-es" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://infonegocios.info/infopublicidad/el-martin-fierro-a-la-mejor-publicidad-es'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
    </Grid>
  )
}

WorkFiat.propTypes = {}

export default WorkFiat