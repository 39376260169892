import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`
const WorkMoviLittleR = props => {
  return (
    <Grid p={3}
    container
    direction="row"
    justifyContent="center"
    >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        >
            <Grid item xs={12} pt={3}>
                <ThemeProvider theme={ApercuPro}>
                    <CssBaseline />
                    <Typography variant='h4'>
                        Movistar: Little Richard
                    </Typography>
                    <Typography variant='h5' py={3}>
                        When Movistar homaged one lucky mom.
                    </Typography>
                </ThemeProvider>
            </Grid>
            <Grid item xs={12} pb={1}>
                <ThemeProvider theme={ApercuPro}>
                    <CssBaseline />
                    <Typography variant='body2'>
                        All the affection of a mother shown with a hand-knit sweater so that her mountaineer son will be protected from the cold. And all that love comes back in a video call from up on the mountain, no matter how ridiculous it looks.
                    </Typography>
                </ThemeProvider>
            </Grid>
            <Grid item xs={12} pb={3}>
                <ThemeProvider theme={ApercuPro}>
                    <CssBaseline />
                    <Typography variant='body2'>
                        Creative Agency: Dhélet VMLY&R / Ivan Zimmermann (Creative Director)
                    </Typography>
                    <Typography variant='body2'>
                        Production Company: Landia / Dir. Maxi Blanco
                    </Typography>
                </ThemeProvider>
            </Grid>
            <Grid container 
                className='player-wrapper' 
                pb={2}>
                <ReactPlayer
                    url= 'https://vimeo.com/482721305'
                    className='react-playeri'          
                    controls
                    width='100%'
                    height='100%'
                    />
            </Grid>
            <Grid item xs={12} pb={3}>
                <ThemeProvider theme={ApercuPro}>
                    <CssBaseline />
                    <Box pt={3}>
                        <Stack 
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={1}>
                                <StyledLink 
                                    textTransform="none" 
                                    lineHeight= {1} 
                                    variant="body2" 
                                    href="https://www.dossiernet.com.ar/articulo/movistar-presenta-ricardito-su-nueva-campana-por-el-dia-de-la-madre-creada-por-dhelet-yr/13487" 
                                    underline="hover" 
                                    target="_blank" 
                                    rel="noopener">
                                {'https://www.dossiernet.com.ar/articulo/movistar-presenta-ricardito-su-nueva-campana-por-el-dia-de-la-madre-creada-por-dhelet-yr/13487'}
                                </StyledLink>
                                <StyledLink 
                                    textTransform="none" 
                                    lineHeight= {1} 
                                    variant="body2" 
                                    href="https://www.luerzersarchive.com/en/magazine/commercial-detail/telefonica-movistar-66652.html" 
                                    underline="hover" 
                                    target="_blank" 
                                    rel="noopener">
                                {'https://www.luerzersarchive.com/en/magazine/commercial-detail/telefonica-movistar-66652.html'}
                                </StyledLink>
                                <StyledLink 
                                    textTransform="none" 
                                    lineHeight= {1} 
                                    variant="body2" 
                                    href="https://www.lbbonline.com/news/movistar-releases-heartwarming-spot-little-richard-to-celebrate-mothers-day" 
                                    underline="hover" 
                                    target="_blank" 
                                    rel="noopener">
                                {'https://www.lbbonline.com/news/movistar-releases-heartwarming-spot-little-richard-to-celebrate-mothers-day'}
                                </StyledLink>
                                <StyledLink 
                                    textTransform="none" 
                                    lineHeight= {1} 
                                    variant="body2" 
                                    href="https://www.totalmedios.com/nota/32900/movistar-lanza-ricardito-su-campana-por-el-dia-de-la-madre" 
                                    underline="hover" 
                                    target="_blank" 
                                    rel="noopener">
                                {'https://www.totalmedios.com/nota/32900/movistar-lanza-ricardito-su-campana-por-el-dia-de-la-madre'}
                                </StyledLink>
                        </Stack>
                    </Box>
                </ThemeProvider>
            </Grid>
        </Grid>
    </Grid>
)
}

WorkMoviLittleR.propTypes = {}

export default WorkMoviLittleR