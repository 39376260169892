import React from 'react'
import PropTypes from 'prop-types'
import AppFrame from './../components/AppFrame'
import About from '../components/About'
import Works from '../components/Works'
import WorkCocaCola from '../components/works/WorkCocaCola'
import WorkBudwieser from '../components/works/WorkBudwieser'
import WorkBecker from '../components/works/WorkBecker'
import WorkMoviLittleR from '../components/works/WorkMoviLittleR'
import WorkActimel from '../components/works/WorkActimel'
import WorkMoviManiac from '../components/works/WorkMoviManiac'
import WorksWarsteiner from '../components/works/WorksWarsteiner'
import WorkMoviComanche from '../components/works/WorkMoviComanche'
import WorkCocaColaMarta from '../components/works/WorkCocaColaMarta'
import WorkToro from '../components/works/WorkToro'
import WorkYpf from '../components/works/WorkYpf'
import WorkWorkFiat from '../components/works/WorkFiat'
import WorkOlx from '../components/works/WorkOlx'
import WorkMoviGalaxy from '../components/works/WorkMoviGalaxy'
import WorkBonobon from '../components/works/WorkBonobon'
import WorkMoviChoose from '../components/works/WorkMoviChoose'
import WorkViltar from '../components/works/WorkViltar'
import WorkFarmacity from '../components/works/WorkFarmacity'
import Recognitions from '../components/Recognitions'
import Experience from '../components/Experience'
import WorkAbinbev from '../components/works/WorkAbinbev'
import WorkUbereats from '../components/works/WorkUbereats'
import WorkAlmaMora from '../components/works/WorkAlmaMora'
import WorkCocaColaNS from '../components/works/WorkCocaColaNS'

const MainPage = ({frame}) => {

    let rightframe;
      if (frame === 'about'){
        rightframe = <About/>;
      }  if (frame === 'works'){
        rightframe = <Works/>
      } if (frame === 'recognitions'){
        rightframe = <Recognitions/>;
      }  if (frame === 'experience'){
        rightframe = <Experience/>
      } if (frame === 'cocacola'){
        rightframe = <WorkCocaCola/>
      } if (frame === 'budweiser'){
        rightframe = <WorkBudwieser/>
      } if (frame === 'becker'){
        rightframe = <WorkBecker/>
      } if (frame === 'littleRichard'){
        rightframe = <WorkMoviLittleR/>
      } if (frame === 'actimel'){
        rightframe = <WorkActimel/>
      } if (frame === 'maniac'){
        rightframe = <WorkMoviManiac/>
      } if (frame === 'warsteiner'){
        rightframe = <WorksWarsteiner/>
      } if (frame === 'cocacolamarta'){
        rightframe = <WorkCocaColaMarta/>
      } if (frame === 'toro'){
        rightframe = <WorkToro/>
      } if (frame === 'ypf'){
        rightframe = <WorkYpf/>
      } if (frame === 'fiat'){
        rightframe = <WorkWorkFiat/>
      } if (frame === 'olx'){
        rightframe = <WorkOlx/>
      } if (frame === 'galaxy'){
        rightframe = <WorkMoviGalaxy/>
      } if (frame === 'bonobon'){
        rightframe = <WorkBonobon/>
      } if (frame === 'choose'){
        rightframe = <WorkMoviChoose/>
      } if (frame === 'viltar'){
        rightframe = <WorkViltar/>
      } if (frame === 'farmacity'){
        rightframe = <WorkFarmacity/>
      } if (frame === 'abinbev'){
        rightframe = <WorkAbinbev/>
      } if (frame === 'ubereats'){
        rightframe = <WorkUbereats/>
      } if (frame === 'almamora'){
        rightframe = <WorkAlmaMora/>
      } if (frame === 'cocacolanosugar'){
        rightframe = <WorkCocaColaNS/>
      }
  return (
    <AppFrame>
        {rightframe}
    </AppFrame>
    
  )
}

MainPage.propTypes = {}

export default MainPage