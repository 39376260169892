import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import { styled } from '@mui/material/styles';
import Imagenes from './../Imagenes'

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

const WorkActimel = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Actimel: Wake up, shake up
                </Typography>
                <Typography variant='h5' py={3}>
                    When Actimel made everyone shake their little bottles for no reason at all.
                </Typography>
                <Typography variant='body2' pb={1}>
                    We found that lots of people shake Actimel's bottle before drinking it for no logical reason, but they do it anyway. We turned that insight into a campaign, explaining logically that the first shake goes through your body, waking you up and getting you ready for the rest of the day.
                </Typography>               
                <Typography variant='body2'>
                    Creative Agency: Dhélet VMLY&R / Ivan Zimmermann (Creative Director)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: Out-sider / Dir. Bart Timmer
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/482715889'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/482716780'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/482716330'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/482716151'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            pt={1}
            >
            <Grid item xs={12}>
                <Img alt="ivan zimmermann" src={Imagenes.actimel}/>
            </Grid>
            <Grid item xs={12}>
                <Img alt="ivan zimmermann" src={Imagenes.actimel2}/>
            </Grid>
            <Grid item xs={12}>
                <Img alt="ivan zimmermann" src={Imagenes.actimel4}/>
            </Grid>
        </Grid>
    </Grid>
  )
}

WorkActimel.propTypes = {}

export default WorkActimel