import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const WorkMoviManiac = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container 
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Movistar: Maniac
                </Typography>
                <Typography variant='h5' py={3}>
                    When Movistar did a weird version of Michael Sambello’s “Maniac”.
                </Typography>
                <Typography variant='body2' pt={3} pb={1}>
                    Michael Sambello's classic '80s hit played by a band of musicians who had neither the instruments nor the Flashdance references, but did have credit on their smartphones to Google the chords and play a very singular version of this famous song.
                </Typography>
                <Typography variant='body2' >
                    Creative Agency: Dhélet VMLY&R / Ivan Zimmermann (Creative Director)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: Ladoble / Dir. Esteban Sapir
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/482720263'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/482722752'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.totalmedios.com/nota/32255/dhelet-y-r-y-movistar-lanzan-una-nueva-campana-de-la-promo-multiplicate" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.totalmedios.com/nota/32255/dhelet-y-r-y-movistar-lanzan-una-nueva-campana-de-la-promo-multiplicate'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.mercadonegro.pe/publicidad/campanas/movistar-lanza-maniac-bamboleo/" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.mercadonegro.pe/publicidad/campanas/movistar-lanza-maniac-bamboleo/'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.adlatina.com/campañas/maniacbamboleo" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.adlatina.com/campañas/maniacbamboleo'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
        </Grid>
    </Grid>
  )
}

WorkMoviManiac.propTypes = {}

export default WorkMoviManiac