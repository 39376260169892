import React, { useEffect }  from 'react'
import WorkItemImg from './WorkItemImg'
import Grid from '@mui/material/Grid'
import WorkItemVid from './WorkItemVid';
import Videos from './Videos';
import Imagenes from './Imagenes'


const itemData = [
  {
    img: Imagenes.bud,
    title: '"we brew birra"',
  },
  {
    img: Imagenes.movicom,
    title: '"commanche comeback"',
  },
  {
    img: Imagenes.warsteiner,
    title: '"worst ad ever"',
  },
  {
    img: Imagenes.bonobon,
    title: '"advice"',
  },
  {
    img: Imagenes.viltar,
    title: '"penguin"',
  },
  {
    img: Imagenes.farmacity,
    title: '"health is also contagious"',
  }
];


const itemDataVid = [
  {
    vid: Videos.cocaarg,
    title: '"you were born here"',
  },
  {
    vid: Videos.becker,
    title: '"a beer won’t change the world"',
  },
  {
    vid: Videos.moviRicardito,
    title: '"little Richard"',
  },
  {
    vid: Videos.actimel,
    title: '"wake up, shake up"',
  },
  {
    vid: Videos.moviManiac,
    title: '"maniac"',
  },
  {
    vid: Videos.cocamar,
    title: '"m.a.r.t.h.a vs robots"',
  },
  {
    vid: Videos.toro,
    title: '"paddlers"',
  },
  {
    vid: Videos.elaion,
    title: '"safe and trust"',
  },
  {
    vid: Videos.fiat,
    title: '"happy streets"',
  },
  {
    vid: Videos.olx,
    title: '"selling it’s easy"',
  },
  {
    vid: Videos.galaxia,
    title: '"galaxy"',
  },
  {
    vid: Videos.choose,
    title: '"choose everything"',
  },
  {
    vid: Videos.abinbev,
    title: '"hairy"',
  },
  {
    vid: Videos.ubereats,
    title: '"it’s everything"',
  },
  {
    vid: Videos.almamora,
    title: '"love is inevitable"',
  },
  {
    vid: Videos.cocanosu,
    title: '"enjoy the taste of No"',
  }
];

const Works = props => {

  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Ajusta el ancho según tus necesidades

    if (!isMobile) {
        const element = document.getElementById('works');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }}, []);

  return (
    <Grid id='works' container direction="row" alignItems="center" justifyContent="center" spacing={1} className="marginGrid">
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/abinbev'} elemento={itemDataVid[12].vid} description={itemDataVid[12].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/ubereats'} elemento={itemDataVid[13].vid} description={itemDataVid[13].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/cocacola'} elemento={itemDataVid[0].vid} description={itemDataVid[0].title}/>
        </Grid>
        <Grid item xs={12} md={4} >
          <WorkItemImg section={'/budweiser'} elemento={itemData[0].img} description={itemData[0].title}/>
        </Grid>
        <Grid item xs={12} md={4} >
          <WorkItemVid section={'/becker'} elemento={itemDataVid[1].vid} description={itemDataVid[1].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/littleRichard'} elemento={itemDataVid[2].vid} description={itemDataVid[2].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/actimel'} elemento={itemDataVid[3].vid} description={itemDataVid[3].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/maniac'} elemento={itemDataVid[4].vid} description={itemDataVid[4].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/cocacolamarta'} elemento={itemDataVid[5].vid} description={itemDataVid[5].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/almamora'} elemento={itemDataVid[14].vid} description={itemDataVid[14].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemImg section={'/warsteiner'} elemento={itemData[2].img} description={itemData[2].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/ypf'} elemento={itemDataVid[7].vid} description={itemDataVid[7].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/cocacolanosugar'} elemento={itemDataVid[15].vid} description={itemDataVid[15].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/galaxy'} elemento={itemDataVid[10].vid} description={itemDataVid[10].title}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <WorkItemVid section={'/choose'} elemento={itemDataVid[11].vid} description={itemDataVid[11].title}/>
        </Grid>
    </Grid>
  )
}

Works.propTypes = {}

export default Works