import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const WorkMoviGalaxy = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Movistar: Galaxy
                </Typography>
                <Typography variant='h5' py={3}>
                    When Movistar relaunched its new brand worldwide..
                </Typography>
                <Typography variant='body2'  pb={1}>
                    A giant Telco wanted a giant brand repositioning. Is there anything bigger than the galaxy? This is how we relaunched the new brand platform "Choose everything". Which we then fed with dozens of stories where Movistar, through its services (wifi, 4g, tv)  opens the doors to a world full of infinite possibilities. Like the galaxy.
                </Typography>
                <Typography variant='body2'>
                    Creative Agency: Dhelet VMLYR / Ivan Zimmermann (Creative Director)
                </Typography>
                <Typography variant='body2' pb={3}>
                    Production Company: Ladoble / Dir. Esteban Sapir
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid container className='player-wrapper' >
            <ReactPlayer
                url= 'https://vimeo.com/518602261'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container pt={2}>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://infonegocios.info/infopublicidad/movistar-lanza-galaxia-por-y-r-group-latam" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://infonegocios.info/infopublicidad/movistar-lanza-galaxia-por-y-r-group-latam'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.dossiernet.com.ar/articulo/galaxia-el-nuevo-comercial-de-movistar-creado-por-young--rubicam-group-latam/11106" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.dossiernet.com.ar/articulo/galaxia-el-nuevo-comercial-de-movistar-creado-por-young--rubicam-group-latam/11106'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticias/detalle/42896/undefined" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticias/detalle/42896/undefined'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://insiderlatam.com/movistar-lanza-su-nueva-campana-institucional-galaxia/" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://insiderlatam.com/movistar-lanza-su-nueva-campana-institucional-galaxia/'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
        </Grid>
    </Grid>
  )
}

WorkMoviGalaxy.propTypes = {}

export default WorkMoviGalaxy