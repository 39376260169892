import ivan from './../img/ivan.jpg';
import bud from './../img/bud.png';
import bud2 from './../img/bud2.jpeg';
import bud3 from './../img/bud3.jpeg';
import bud4 from './../img/bud4.png';
import bud5 from './../img/bud5.png';
import movicom from './../img/movistar_comanche.png';
import commanche from './../img/commanche.jpg';
import warsteiner from'./../img/warsteiner.png';
import warsteiner2 from'./../img/warsteiner2.jpeg';
import warsteiner3 from'./../img/warsteiner3.png';
import warsteiner4 from'./../img/warsteiner4.png';
import warsteiner5 from'./../img/warsteiner5.png';
import fiat2 from './../img/fiat2.jpg';
import bonobon from'./../img/bonobon.png';
import viltar from './../img/viltar.png';
import farmacity from './../img/farmacity.png';
import farmacity2 from './../img/farmacity2.jpeg';
import farmacity3 from './../img/farmacity3.jpeg';
import farmacity4 from './../img/farmacity4.jpeg';
import actimel from './../img/actimel.jpg';
import actimel2 from './../img/actimel2.jpg';
import actimel3 from './../img/actimel3.png';
import actimel4 from './../img/actimel4.png';
import premios from './../img/premios.png';
import cannes from './../img/cannes.png';
import diente from './../img/diente.png';
import effie from './../img/effie.png';
import elojo from './../img/elojo.png';
import iab from './../img/iab.png';
import lapiz from './../img/lapiz.png';
import sol from './../img/sol.png';
import wave from './../img/wave.png';
import fiap from './../img/fiap.png';
import lurzer from './../img/lurzer.png';
import logos from './../img/logos.png';
import ccnsfriendzone from './../img/ccnsfriendzone.png';
import ccnsmosaic from './../img/ccnsmosaic.png';
import ccnsmosaicpost from './../img/ccnsmosaicpost.png';

import ar from './../img/flags/ar.png';
import br from './../img/flags/br.png';
import cl from './../img/flags/cl.png';
import de from './../img/flags/de.png';
import ea from './../img/flags/ea.jpg';
import es from './../img/flags/es.png';
import fr from './../img/flags/fr.png';
import gb from './../img/flags/gb.png';
import it from './../img/flags/it.png';
import mx from './../img/flags/mx.png';
import nl from './../img/flags/nl.png';
import py from './../img/flags/py.png';
import ru from './../img/flags/ru.png';
import sa from './../img/flags/sa.png';
import us from './../img/flags/us.png';
import za from './../img/flags/za.png';
import pr from './../img/flags/pr.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "ivan": ivan,
    "bud": bud,
    "bud2": bud2,
    "bud3": bud3,
    "bud4": bud4,
    "bud5": bud5,
    "movicom": movicom,
    "commanche": commanche,
    "warsteiner": warsteiner,
    "warsteiner2": warsteiner2,
    "warsteiner3": warsteiner3,
    "warsteiner4": warsteiner4,
    "warsteiner5": warsteiner5,
    "fiat2": fiat2,
    "bonobon": bonobon,
    "viltar": viltar,
    "farmacity": farmacity,
    "farmacity2": farmacity2,
    "farmacity3": farmacity3,
    "farmacity4": farmacity4,
    "actimel": actimel,
    "actimel2": actimel2,
    "actimel3": actimel3,
    "actimel4": actimel4,
    "premios": premios,
    "cannes": cannes,
    "diente": diente,
    "effie": effie,
    "elojo": elojo,
    "iab": iab,
    "lapiz": lapiz,
    "sol": sol,
    "wave": wave,
    "fiap": fiap,
    "lurzer": lurzer,
    "logos": logos,
    "ccnsfriendzone": ccnsfriendzone,
    "ccnsmosaic": ccnsmosaic,
    "ccnsmosaicpost": ccnsmosaicpost,
    "ar": ar,
    "br": br,
    "cl": cl,
    "de": de,
    "ea": ea,
    "es": es,
    "fr": fr,
    "gb": gb,
    "it": it,
    "mx": mx,
    "nl": nl,
    "py": py,
    "ru": ru,
    "sa": sa,
    "us": us,
    "za": za,
    "pr": pr,
}