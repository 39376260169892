import React, { useEffect }  from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    styled,
    ThemeProvider,
  } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from './ApercuPro.jsx';
import Imagenes from './Imagenes';
import { Grid } from '@mui/material';

const Img = styled('img')(({ theme }) => ({
    margin: '0',
    padding: '0',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
}));

const Experience = props => {

    useEffect(() => {
        const isMobile = window.innerWidth <= 768; // Ajusta el ancho según tus necesidades
    
        if (!isMobile) {
            const element = document.getElementById('experience');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }}, []);

  return (
    <Box id='experience' p={3} sx={{ pt:5 }}>
        <ThemeProvider theme={ApercuPro}>
        <CssBaseline />        
            <Typography variant="h5" component="div">
                Freelance experience
            </Typography> 
            <Typography variant="h5" component="div" gutterBottom>
                / Selected projects
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.us} alt="USA Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Corona / Global Pitch / Activation ideas / 2024
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.us} alt="USA Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Verizon / Local Pitch / Integrated campaign / 2024
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.cl} alt="Chile Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Entel / Local Pitch / Integrated campaign  / 2024
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.cl} alt="Chile Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Entel / Local Pitch / Integrated campaign / 2023
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.pr} alt="Peru Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Entel / Local Pitch / Integrated campaign / 2023
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ea} alt="Emirates Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Saudi Arabia Tourism / Global pitch / Integrated campaign / 2022
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.mx} alt="Mexico Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        UBER Eats / Regional Pitch / Integrated campaign / 2022
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ea} alt="Emirates Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Hilton / Regional Pitch / Integrated campaign / 2022
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        AB InBev / Local Pitch / TVC ideas / 2022
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.mx} alt="Mexico Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Amazon Prime Video / Local Pitch / Integrated campaign / 2022
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.nl} alt="Nederland Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Gatorade / Global Pitch / Integrated campaign / 2022
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Coca-Cola / Christmas Global Pitch / Integrated campaign / 2022
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Fernet Branca / Local Pitch / Integrated campaign / 2022
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.us} alt="USA Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Amstel Ultra / Regional Pitch / TVC ideas / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.us} alt="USA Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        AB InBev / Local Pitch / Activation ideas / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Visa Debit / Global Pitch / TVC ideas / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        YPF Elaion / Local Pitch / TVC ideas / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.de} alt="Germany Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Nivea / Local Pitch / TVC ideas / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.cl} alt="Chile Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Global 66 / Regional Pitch / TVC ideas / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.br} alt="Brazil Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        UBER Pass / Regional Pitch / Integrated campaign / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Alma Mora wines / Local Pitch / TVC ideas / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.mx} alt="Mexico Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Cerveza Modelo / Local Pitch / Activation ideas / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.us} alt="USA Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Kangaroo home security / Local Pitch / TVC ideas / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        National Censo 2022 / Local Pitch / Integrated campaign / 2021
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Banco Nación / Local Pitch / Integrated campaign / 2020
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ru} alt="Rusia Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Ivy farm cultured-meat / Global Pitch / Integrated campaign / 2020
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Coca-Cola No sugar / Global Pitch / Integrated campaign / 2020
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.fr} alt="France Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Peugeot / Global Pitch / Integrated campaign / 2020
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.mx} alt="Mexico Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Corona / Regional Pitch / Activation ideas / 2020
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        MODO / Local Pitch / Integrated campaign / 2020
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.py} alt="Paraguay Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Sbarro / Local Pitch / Activacion ideas / 2020
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Disney+ / Regional Pitch / Activation ideas / 2020
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.cl} alt="Chile Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Entel / Local Pitch / Integrated campaign / 2020
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.cl} alt="Chile Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Becker / Local Pitch / Integrated campaign / 2019
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Budweiser Lollapalooza / Local Pitch / Activation ideas / 2019
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.us} alt="USA Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Mastercard / Regional Pitch / TVC ideas / 2019
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Movistar Play / Regional Pitch / Integrated campaign / 2019
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.us} alt="USA Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Miller / Local Pitch / TVC ideas / 20219
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        AMEX / Local Pitch / Activation ideas / 2019
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.it} alt="Italy Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Schar / Global Pitch / Integrated campaign / 2019
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        7Up / Regional Pitch / TVC ideas / 2019
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.mx} alt="Mexico Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        WeWork / Regional Pitch / Digital campaign / 2019
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Budweiser / Brand Relaunch / Integrated campaign / 2018
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Coca-Cola / Local Pitch / TVC ideas / 2018
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.pr} alt="Peru Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Ab InBev / Local Pitch / Integrated campaign / 2018
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Coca-Cola / Zero Sugar / TVC ideas / 2018
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        JAC motors / Local Pitch / Integrated campaign / 2018
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.gb} alt="Britain Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Actimel / Local pitch / Integrated campaign / 2018
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.br} alt="Brazil Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Skol / Local Pitch / Activation ideas / 2018
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Toro Wine / Local Pitch / TVC ideas / 2018
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}} mb={5}>
                <img src={Imagenes.ar} alt="Argentina Flag" width="17" height="12"/>
                <Box sx={{ flexGrow: 1 }} borderBottom={1} ml={1}>
                    <Typography variant="body2" lineHeight={1.5} >
                        Ualá / Local Pitch / Branding & Digital campaign / 2018
                    </Typography>
                </Box>
            </Box>
            <Typography variant="h5" component="div" gutterBottom>
                Agency experience
            </Typography>        
            <Typography variant="body2">
                _2018-today
            </Typography>        
            <Typography variant="body2" pb={2}>
                Freelance Creative Director
            </Typography>
            <Typography variant="body2">
                _2018
            </Typography>        
            <Typography variant="body2">
                AFRICA
            </Typography>
            <Typography variant="body2">
                Role: Freelance Creative Director
            </Typography>
            <Typography variant="body2" pb={2}>
                Client: Budweiser
            </Typography>
            <Typography variant="body2">
                _2016-2018
            </Typography>        
            <Typography variant="body2">
                Dhélet VMLY&R (WPP)
            </Typography>
            <Typography variant="body2">
                Role: Creative Director
            </Typography>
            <Typography variant="body2" pb={2}>
                Clients: Movistar (Telefónica), Actimel, Activia (Danone), Coca-Cola
            </Typography>        
            <Typography variant="body2">
                _2015-2016
            </Typography>        
            <Typography variant="body2">
                Leo Burnett Argentina
            </Typography>        
            <Typography variant="body2">
                Role: Creative Director
            </Typography>        
            <Typography variant="body2" pb={2}>    
                Clients: Fiat, Warsteiner, Samsung, Arcor
            </Typography>        
            <Typography variant="body2">
                _2014-2015
            </Typography>        
            <Typography variant="body2">
                Ponce Buenos Aires (MullenLowe)
            </Typography>        
            <Typography variant="body2">
                Role: Sr. Art Director
            </Typography>        
            <Typography variant="body2" pb={2}>
                Clients: Personal (Telecom), Axe, Rexona (Unilever)
            </Typography>        
            <Typography variant="body2">
                _2009-2014
            </Typography>        
            <Typography variant="body2">            
                Young & Rubicam Argentina
            </Typography>        
            <Typography variant="body2">
                Role: Sr. Art Director
            </Typography>        
            <Typography variant="body2" pb={2}>
                Clients: Coca-Cola, Directv, Visa, Danone, Quilmes (AB InBev), Movistar (Telefónica), OLX, Farmacity, Banco Galicia, among many others
            </Typography>        
            <Typography variant="body2">
                _2008-2009
            </Typography>        
            <Typography variant="body2">
                El Cielo Agulla & Baccetti
            </Typography>        
            <Typography variant="body2">
                Role: Art Director
            </Typography>        
            <Typography variant="body2" pb={2}>
                Clients: YPF, Claro, Gamesa (Mexico)
            </Typography>        
            <Typography variant="body2">
                _2006-2008
            </Typography>        
            <Typography variant="body2">
                HAVAS Buenos Aires
            </Typography>        
            <Typography variant="body2">
                Role: Art Director
            </Typography>        
            <Typography variant="body2">
                Clients: Reckitt Benckiser, Land Rover, among others.
            </Typography>        

            <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            pt={4}>
                <Img alt="experience logos" src={Imagenes.logos}/>
            </Grid>

            <Typography variant="h5" component="div" gutterBottom pt={3}>
                You can ask for recommendations from this amazing guys that have worked with me:
            </Typography> 
            <Typography variant="body2">
                Martin Mercado / CCO Mercado Mccann
            </Typography>
            <Typography variant="body2">
                Nino Goldberg / CCO VMLY&R Latam
            </Typography>        
            <Typography variant="body2">
                Dani Minaker y Patan Tarazaga / CCO Wunderman Thompson Latam
            </Typography>
            <Typography variant="body2">
                Rodrigo Grau / Co-Founder ISLA república
            </Typography>
            <Typography variant="body2">
                Maxi Itzkoff / Co-Founder Slap Global
            </Typography>        
            <Typography variant="body2">
                Matias Menendez / ECD Africa Brazil
            </Typography>        
            <Typography variant="body2">
                Joaquín Cubría / CCO & Partner of GUT (President of the Argentinian Creative Circle)
            </Typography>        
            <Typography variant="body2">
                Fabio Mazzia / CCO Publicis Argentina
            </Typography>        
            <Typography variant="body2">    
                Fernando Bellotti / President Leo Burnett Buenos Aires
            </Typography>        
            <Typography variant="body2">
                Carlos Perez / MMA Global BBDO
            </Typography>        
            <Typography variant="body2">
                Haydee Villanueva / My mom
            </Typography>        
    </ThemeProvider>
    </Box>
  )
}

Experience.propTypes = {}

export default Experience