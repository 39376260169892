import abinbev from './../vids/abinbev.mp4'
import ubereats from './../vids/ubereats.mp4'
import actimel from './../vids/actimelma.mp4';
import cocaarg from './../vids/cocaargma.mp4';
import elaion from './../vids/elaionma.mp4';
import galaxia from './../vids/galaxia.mp4';
import olx from './../vids/olxma.mp4';
import robots from './../vids/robotsma.mp4';
import toro from './../vids/toroma.mp4';
import cocamar from './../vids/robotsma.mp4';
import moviManiac from './../vids/movi_maniac.mp4';
import moviRicardito from './../vids/movi_ricardito.mp4';
import fiat from './../vids/fiat.mp4';
import becker from './../vids/becker.mp4';
import choose from './../vids/movi_choose.mp4';
import cocanosu from './../vids/cocacolanosugar_wb.mp4';
import almamora from './../vids/almamorawine_wb.mp4';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "abinbev": abinbev,
    "ubereats": ubereats,
    "actimel": actimel,
    "cocaarg": cocaarg,
    "elaion": elaion,
    "galaxia": galaxia,
    "olx": olx,
    "robots": robots,
    "toro": toro,
    "cocamar": cocamar,
    "moviManiac": moviManiac,
    "moviRicardito": moviRicardito,
    "fiat": fiat,
    "becker": becker,
    "choose": choose,
    "cocanosu": cocanosu,
    "almamora": almamora,
}