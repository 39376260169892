import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import MainPage from './pages/MainPage'

function App() {

  return (
    <Router>
        <Routes>
            <Route exact path="/" element={<MainPage frame={'works'}/>}/>
            <Route path="/about" element={<MainPage frame={'about'}/>}/>                                                            
            <Route path="/works" element={<MainPage frame={'works'}/>}/>                                                            
            <Route path="/recognitions" element={<MainPage frame={'recognitions'}/>}/>                                                            
            <Route path="/experience" element={<MainPage frame={'experience'}/>}/>                                                                                    
            <Route path="/cocacola" element={<MainPage frame={'cocacola'}/>}/>
            <Route path="/budweiser" element={<MainPage frame={'budweiser'}/>}/>
            <Route path="/becker" element={<MainPage frame={'becker'}/>}/>
            <Route path="/littleRichard" element={<MainPage frame={'littleRichard'}/>}/>
            <Route path="/actimel" element={<MainPage frame={'actimel'}/>}/>
            <Route path="/maniac" element={<MainPage frame={'maniac'}/>}/>
            <Route path="/warsteiner" element={<MainPage frame={'warsteiner'}/>}/>
            <Route path="/almamora" element={<MainPage frame={'almamora'}/>}/>
            <Route path="/cocacolamarta" element={<MainPage frame={'cocacolamarta'}/>}/>
            <Route path="/toro" element={<MainPage frame={'toro'}/>}/>
            <Route path="/ypf" element={<MainPage frame={'ypf'}/>}/>
            <Route path="/fiat" element={<MainPage frame={'fiat'}/>}/>
            <Route path="/cocacolanosugar" element={<MainPage frame={'cocacolanosugar'}/>}/>
            <Route path="/galaxy" element={<MainPage frame={'galaxy'}/>}/>
            <Route path="/bonobon" element={<MainPage frame={'bonobon'}/>}/>
            <Route path="/choose" element={<MainPage frame={'choose'}/>}/>
            <Route path="/viltar" element={<MainPage frame={'viltar'}/>}/>
            <Route path="/farmacity" element={<MainPage frame={'farmacity'}/>}/>
            <Route path="/abinbev" element={<MainPage frame={'abinbev'}/>}/>
            <Route path="/ubereats" element={<MainPage frame={'ubereats'}/>}/>
        </Routes>
    </Router>
  );
}

export default App;
