
import {
    createTheme
  } from '@mui/material/styles';

import Apercu from './../fonts/ApercuPro.woff'
  
  
const ApercuPro = createTheme({
    typography: {
      fontFamily: 'ApercuPro',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
            font-family: 'ApercuPro';
            src: local('ApercuPro-Regular'),
                url(${Apercu}) format('woff');
            font-weight: normal;
            font-style: regular;
            font-display: swap;
        }
        `,
      },
    },
  });

  export default ApercuPro;