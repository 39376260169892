import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'

const WorkUbereats = props => {
  return (
    <Grid p={3}
    container
    direction="column"
    justifyContent="center"
    >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    UBER EATS: It's everything
                </Typography>
                <Typography variant='h5' py={3}>
                    When Uber Eats started delivering more than food.
                </Typography>
                <Typography variant='body2' pb={1}>
                    Uber Eats is the most popular food delivery app. But we had to communicate a new big feature. From now on, in addition to food, you can also order supermarket supplies, pharmacy products, and beverages of all kinds. In other words, you can get anything. That's why we wanted to make it clear that whatever the situation could turn into, in Uber Eats you can order anything. Because Uber Eats it's everything.
                </Typography>               
                <Typography variant='body2'>
                    Creative Agency: Mercado Mccann / Ivan Zimmermann (Freelance Creative Director)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: Ladoble / Dir. Esteban Sapir
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/762392519'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/762392353'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/762392206'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
    </Grid>
    )
}

WorkUbereats.propTypes = {}

export default WorkUbereats