import React from 'react'
import PropTypes from 'prop-types'
import PresHeader from './PresHeader';
import PresIntro from './PresIntro';
import PresInfo from './PresInfo';
import Grid from '@mui/material/Grid'
import PresMenu from './PresMenu';

const Presentation = props => {

  return (
    <>
      <Grid className='height-50'
        container pt={0} px={3}> 
          <PresHeader/> 
          <PresIntro/>
      </Grid> 
      <Grid className='height-50'
        container> 
        <Grid
            container 
            spacing={0.5}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            px={3}
            mt={3}>
            <PresMenu/>
        </Grid>
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-end"
            px={3}
            pt={0} 
            >
            <PresInfo/>
        </Grid>
      </Grid>
    </>
  )
}

Presentation.propTypes = {}

export default Presentation
