import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BsPlus } from 'react-icons/bs'
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import { scroller } from "react-scroll";

const PresMenu = props => {

  const StyledButton = styled(Button)`
    text-transform: none;
    text-align: left;
    font-family: ApercuPro;
    font-size: 1.5rem;
    border: none;
    background-image: none;
    outline: 0;
    box-shadow: none;
    justify-content: flex-start;
    line-height: 1.2;
    text-align: center;
    &:hover {
      background: none;
    };
    `

  const theme = createTheme({
    palette: {
      primary: {
        main: '#212121',
      },
      secondary: {
        main: '#f50057',
      },
    },
  });

  const StyledButtonGroup = styled(ButtonGroup)({
    // change the button group dividers color
    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
      borderColor: "transparent"
    }
  });

  const scrollTo = () => {
    scroller.scrollTo('appframe', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  const [selectedBtn, setSelectedBtn] = useState(1);
  const history = useNavigate()

  const onClickHandler = (menu, section) => {
      setSelectedBtn(menu)
      history(section)
      scrollTo()
  }
  

    return (
    
      <StyledButtonGroup disableElevation variant="contained" orientation="vertical">
        <ThemeProvider theme={theme}>
        <CssBaseline />
            <StyledButton disableFocusRipple disableRipple variant="text" color={selectedBtn === 1 ? "secondary" : "primary"} onClick={()=>onClickHandler(1, '/works')} startIcon={<BsPlus/>} >Selected works</StyledButton>
            <StyledButton disableFocusRipple disableRipple variant="text" color={selectedBtn === 2 ? "secondary" : "primary"} onClick={()=>onClickHandler(2, '/recognitions')} startIcon={<BsPlus/>} >Recognition</StyledButton>
            <StyledButton disableFocusRipple disableRipple variant="text" color={selectedBtn === 3 ? "secondary" : "primary"} onClick={()=>onClickHandler(3, '/experience')} startIcon={<BsPlus/>} >60” CV</StyledButton>
            <StyledButton disableFocusRipple disableRipple variant="text" color={selectedBtn === 4 ? "secondary" : "primary"} onClick={()=>onClickHandler(4, '/about')} startIcon={<BsPlus/>} >About</StyledButton>
        </ThemeProvider>
      </StyledButtonGroup>
  )
}

PresMenu.propTypes = {}

export default PresMenu