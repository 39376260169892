import React, { useEffect }  from 'react'
import Grid from '@mui/material/Grid'
import {
  styled,
  ThemeProvider,
} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Imagenes from './Imagenes'
import CssBaseline from '@mui/material/CssBaseline';
import TimesFont from './TimesFont.jsx';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});


const About = props => {

  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Ajusta el ancho según tus necesidades

    if (!isMobile) {
        const element = document.getElementById('about');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }}, []);

  return (
    <Grid id='about' p={3}> 
      <Grid container className='height-50'>
        <Img alt="ivan zimmermann" src={Imagenes.ivan}/>
      </Grid>
      <Grid className='height-50'
        container
        justifyContent="flex-start"
        alignItems="flex-center"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        pt={2}
        >
        <Grid item xs={12}>
        <ThemeProvider theme={TimesFont}>
          <CssBaseline />
            <Typography variant="h4" pb={2}>Ivan did not have an easy birth. He was born by cesarean because he was upside down and the umbilical cord wrapped twice around his neck. We can say that it was his first big pitch. And he won it. Since then he always managed to overcome big challenges.</Typography>
            <Typography variant="h4" pb={2}>His father was a visual artist, so he grew up among canvases, drawings, paint cans, sculptures and museums. He also used to watch tons of movies and tv series, and of course advertising because there was no skip button yet.</Typography>
            <Typography variant="h4" pb={2}>His curiosity was growing. Besides looking at Communication Arts or Luerzer’s Archive books, he started collecting old weekly magazines from barbershops and selecting his favorite print ads. He spent hours analyzing every single detail. Developing into a human library, like a Marvel superhero, like the Hawk Eye of references.</Typography>
            <Typography variant="h4" pb={2}>Then he was lucky enough to work in several creative shops from Buenos Aires, winning international awards and making friends along the way. Currently, as a freelance Creative, he has been participating in local, regional and global pitches. Working back to back with multicultural professionals from top notch agencies.</Typography>
            <Typography variant="h4" pb={2}>Husband, father of two boys. Ivan loves to travel, buying used 35mm analog cameras, gardening, gouda cheese, bikes, music playlists, mate, coffee, dogs, Seinfeld, Boca Juniors, throwing out things like Marie Kondo, draft beer and asados. He sucks at video games and dancing tango.</Typography>
          </ThemeProvider>
        </Grid>

      </Grid>

    </Grid>
  )
}

About.propTypes = {}

export default About