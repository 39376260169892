import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
    color: inherit;
    &:hover {
    background: black;
    color: white;
    };
    `
const WorkAlmaMora = props => {
  return (
    <Grid p={3}
    container
    direction="column"
    justifyContent="center"
    >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Alma Mora wine: Love is inevitable
                </Typography>
                <Typography variant='h5' py={3}>
                    When Alma Mora talked about what nobody wants to talk about.
                </Typography>
                <Typography variant='body2' pb={1}>
                    Alma Mora is a wine whose communication always talk about love and couples. Soul mates. However, on the other side there are many single people who still believe in love. How do we keep talking about love when so many people are still looking for it? By encouraging them to meet someone. To never stop looking for it, because love is inevitable.
                </Typography>               
                <Typography variant='body2'>
                    Creative Agency: La Comunidad / Ivan Zimmermann (Freelance Creative Director)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: Pantera&Co / Dir. Wacho
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/719074253'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container pt={2}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                    <StyledLink py={0.5}
                        textTransform="none" 
                        lineHeight= {1} 
                        variant="body2" 
                        href="https://dossiernet.com.ar/articulo/nueva-campana-de-la-comunidad-para-alma-mora/28682" 
                        underline="hover" 
                        target="_blank" 
                        rel="noopener">
                    {'https://dossiernet.com.ar/articulo/nueva-campana-de-la-comunidad-para-alma-mora/28682'}
                    </StyledLink>
            </ThemeProvider>
        </Grid>
    </Grid>
    )
}

WorkAlmaMora.propTypes = {}

export default WorkAlmaMora