import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const WorkOlx = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid item xs={12} pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    OLX: How much?
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pt={3} pb={1}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                OLX was launching their services in Argentina and needed people to adopt the habit of selling what they no longer use. We decided to show how easy it is to publish those things using the app, and how valuable those things can be for someone else, though you may think it is worth nothing.
            </Typography>
            <Typography variant='h5' py={3}>
                When OLX needed people’s used stuff to sell them.
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={3}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                Creative Agency: Young&Rubicam Buenos Aires / Ivan Zimmermann (Senior Art Director)
            </Typography>
            <Typography variant='body2'>
                Production Company: Ladoble / Dir. Esteban Sapir & Ignacio Gabriel
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' pb={2}>
        <ReactPlayer
            url= 'https://vimeo.com/515455104'
            className='react-playeri'          
            controls
            width='100%'
            height='100%'
            />
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/515453878'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/515454491'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticia/yr-argentina-y-olx-lanzan-cunto-vale/32860" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticia/yr-argentina-y-olx-lanzan-cunto-vale/32860'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.totalmedios.com/nota/21737/young-presenta-la-campana-completa-de-olx-cuanto-vale" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.totalmedios.com/nota/21737/young-presenta-la-campana-completa-de-olx-cuanto-vale'}
                        </StyledLink>                
                </Stack>
            </Box>
        </ThemeProvider>
    </Grid>
  )
}

WorkOlx.propTypes = {}

export default WorkOlx