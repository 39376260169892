import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import {
    ThemeProvider,
  } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import TimesFont from './TimesFont.jsx'


const PresIntro = props => {
  return (
    <Grid container py={2}>
        <ThemeProvider theme={TimesFont}>
          <CssBaseline />
            <Typography variant="h4">Ivan Zimmermann is an Argentinian Creative Director based in Spain, with +15 years of experience in fully-integrated-360-ttl no budget-global-multi target-asap-campaigns for practically all kinds of brands. He loves making ads with powerful insights and he also likes to speak in third person.</Typography>
        </ThemeProvider>
    </Grid>
  )
}

PresIntro.propTypes = {}

export default PresIntro