import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
    color: inherit;
    &:hover {
    background: black;
    color: white;
    };
    `
const WorkCocaCola = props => {
  return (
    <Grid p={3}
    container
    direction="column"
    justifyContent="center"
    >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Coca-Cola: You were born here
                </Typography>
                <Typography variant='h5' py={3}>
                    When Coca-Cola became a 60” viral spreading hope during a huge crisis.
                </Typography>
                <Typography variant='body2' pb={1}>
                    In a difficult year for the country (happening more and more often in Argentina), Coca-Cola wanted to give a positive message people could identify with. Reverting the general feeling of resignation, "You were born here..." revalues all the positive things that being Argentine has, and rescues those most difficult moments that bring those feelings out in each one of us.
                </Typography>               
                <Typography variant='body2'>
                    Creative Agency: GREY Argentina / Ivan Zimmermann (Freelance Creative)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: PRIMO / Dir. Gustavo Taretto
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/515450024'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container pt={2}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                    <StyledLink py={0.5}
                        textTransform="none" 
                        lineHeight= {1} 
                        variant="body2" 
                        href="http://www.latinspots.com/sp/noticias/detalle/48421/te-toc-nacer-ac-el-mensaje-positivo-de-coca-cola-y-grey-argentina" 
                        underline="hover" 
                        target="_blank" 
                        rel="noopener">
                    {'http://www.latinspots.com/sp/noticias/detalle/48421/te-toc-nacer-ac-el-mensaje-positivo-de-coca-cola-y-grey-argentina'}
                    </StyledLink>
                            <StyledLink py={0.5}
                                textTransform="none" 
                                lineHeight= {1} 
                                variant="body2" 
                                href="https://www.reasonwhy.es/actualidad/campanas/grey-argentina-campana-coca-cola-te-toco-nacer-aca" 
                                underline="hover" 
                                target="_blank" 
                                rel="noopener">
                            {'https://www.reasonwhy.es/actualidad/campanas/grey-argentina-campana-coca-cola-te-toco-nacer-aca'}
                            </StyledLink>
                            <StyledLink py={0.5}
                                textTransform="none" 
                                lineHeight= {1} 
                                variant="body2" 
                                href="https://www.cocacoladeargentina.com.ar/novedades/historia--_te-toco-nacer-aca--la-publicidad-que-apela-a-la-argentinidad-y0" 
                                underline="hover" 
                                target="_blank" 
                                rel="noopener">
                            {'https://www.cocacoladeargentina.com.ar/novedades/historia--_te-toco-nacer-aca--la-publicidad-que-apela-a-la-argentinidad-y0'}
                            </StyledLink>
                            <StyledLink py={0.5}
                                textTransform="none" 
                                lineHeight= {1} 
                                variant="body2" 
                                href="https://www.adlatina.com/publicidad/“te-tocó-nacer-acá”-lo-nuevo-de-grey-argentina-para-coca-cola" 
                                underline="hover" 
                                target="_blank" 
                                rel="noopener">
                            {'https://www.adlatina.com/publicidad/“te-tocó-nacer-acá”-lo-nuevo-de-grey-argentina-para-coca-cola'}
                            </StyledLink>
            </ThemeProvider>
        </Grid>
    </Grid>
    )
}

WorkCocaCola.propTypes = {}

export default WorkCocaCola