import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Imagenes from './../Imagenes'

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

const WorkCocaCola = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Movistar: Commanche comeback
                </Typography>
            <Typography variant='body2' pt={3} pb={1}>
                Commanche was one of the most iconic cumbia groups of the '90s in Argentina. 26 years later they got together again on a terrace, just like The Beatles once did. The campaign had such an impact that the song reached the top 50 on Spotify for two weeks. And more importantly, the band was hired to perform at hundreds of weddings and bar mitzvahs.
            </Typography>
            <Typography variant='body2'>
                Creative Agency: Dhélet VMLY&R / Ivan Zimmermann (Creative Director)
            </Typography>
            <Typography variant='body2' pb={3}>
                Production Company: Landia / Dir: Maureen Hufnagel
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid container className='player-wrapper' >
            <ReactPlayer
                url= 'https://vimeo.com/485673916'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        py={1}
        >
            <Grid item xs={12}>
                <Img alt="ivan zimmermann" src={Imagenes.commanche}/>
            </Grid>
        </Grid>
        <Grid container className='player-wrapper'>
            <ReactPlayer
                url= 'https://vimeo.com/486142599'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container pt={2}>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.dossiernet.com.ar/articulo/commanche-lo-nuevo-de-dhelet-y-r-para-movistar/14067" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.dossiernet.com.ar/articulo/commanche-lo-nuevo-de-dhelet-y-r-para-movistar/14067'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://insiderlatam.com/movistar-reune-al-grupo-commanche/" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://insiderlatam.com/movistar-reune-al-grupo-commanche/'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticia/dhlet-y-movistar-bailan-con-commanche/45609" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticia/dhlet-y-movistar-bailan-con-commanche/45609'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.adlatina.com/campañas/cómo-te-lo-digo-commanche" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.adlatina.com/campañas/cómo-te-lo-digo-commanche'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.marketersbyadlatina.com/articulo/4631-movistar-wunderman-y-dhélet-y%26r-“una-canción-pegadiza-logró-todo”" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.marketersbyadlatina.com/articulo/4631-movistar-wunderman-y-dhélet-y%26r-“una-canción-pegadiza-logró-todo”'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
        </Grid>
    </Grid>
  )
}

WorkCocaCola.propTypes = {}

export default WorkCocaCola