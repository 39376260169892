import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Imagenes from './../Imagenes'

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`
const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

const WorkWarsteiner = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Warsteiner: Horse. The worst advertising ever
                </Typography>
                <Typography variant='h5' py={3}>
                    When Warsteiner trolled their own followers.
                </Typography>
                <Typography variant='body2' pb={1}>
                    We created an annoying ad on purpose. Then we launched another version responding to haters. An interactive brand manifesto that trolled our own fans. Why would a brand do that? Because if you like it, fine.
                </Typography>
                <Typography variant='body2'>
                    Creative Agency: Leo Burnett Buenos Aires / Ivan Zimmermann (Creative Director)
                </Typography>
                <Typography variant='body2' pb={3}>
                    Production Company: Agosto / Dir: Diego Irigoyen
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/485665573'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/485665987'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/485666782'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        pt={1}
        >
            <Grid item xs={12} pb={2}>
                <Img alt="Warsteiner advertising" src={Imagenes.warsteiner2}/>
            </Grid>
            <Grid item xs={12} pb={2}>
                <Img alt="Warsteiner advertising" src={Imagenes.warsteiner3}/>
            </Grid>
            <Grid item xs={12} pb={2}>
                <Img alt="Warsteiner advertising" src={Imagenes.warsteiner4}/>
            </Grid>
            <Grid item xs={12} pb={2}>
                <Img alt="Warsteiner advertising" src={Imagenes.warsteiner5}/>
            </Grid>
        </Grid>
        <Grid container pt={2}>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.adweek.com/brand-marketing/warsteiner-created-an-annoying-ad-then-launched-another-version-responding-to-the-haters/" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.adweek.com/brand-marketing/warsteiner-created-an-annoying-ad-then-launched-another-version-responding-to-the-haters/'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://es.adforum.com/creative-work/ad/player/34565156/horse/warsteiner" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://es.adforum.com/creative-work/ad/player/34565156/horse/warsteiner'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.adlatina.com/articulo.php?slug=/publicidad/“caballo”-preestreno-de-leo-burnett-argentina-para-warsteiner" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.adlatina.com/articulo.php?slug=/publicidad/“caballo”-preestreno-de-leo-burnett-argentina-para-warsteiner'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticias/detalle/46380/leo-burnett-argentina-lanza-la-segunda-parte-de-su-campaa-para-cerveza-warsteiner" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticias/detalle/46380/leo-burnett-argentina-lanza-la-segunda-parte-de-su-campaa-para-cerveza-warsteiner'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.dossiernet.com.ar/articulo/nueva-campana-de-leo-burnett-argentina-para-warsteiner/14814" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.dossiernet.com.ar/articulo/nueva-campana-de-leo-burnett-argentina-para-warsteiner/14814'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://infonegocios.info/infopublicidad/warsteiner-responde-malos-comentarios-con-un-nuevo-comercial" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://infonegocios.info/infopublicidad/warsteiner-responde-malos-comentarios-con-un-nuevo-comercial'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
        </Grid>
    </Grid>
  )
}

WorkWarsteiner.propTypes = {}

export default WorkWarsteiner