import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Imagenes from './../Imagenes'

const StyledLink = styled(Link)`
color: inherit;
&:hover {
  background: black;
  color: white;
};
`
const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  

const WorkFarmacity = props => {
  return (
    <Grid p={3}
        container
        direction="column"
        justifyContent="center"
        >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid item xs={12} pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    Farmacity: Health is also contagious
                </Typography>
                <Typography variant='h5' py={3}>
                    When Farmacity talked about the most contagious thing in the world.
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={1}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                Farmacity is the largest pharmacy chain store in Argentina that had never had an advertising campaign. We created the concept "health is also contagious," centered on stories between customers and pharmacists that gives you more than just a pack of pills or cough syrup.
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid item xs={12} pb={3}>
        <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
            <Typography variant='body2'>
                Creative Agency: Young&Rubicam Buenos Aires / Ivan Zimmermann (Senior Art Director)
            </Typography>
            <Typography variant='body2'>
                Production Company: Fight Films / Dir. Adriana Laham
            </Typography>
            </ThemeProvider>
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/515451341'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/515452293'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        pt={1}
        >
            <Grid item xs={12}>
                <Img alt="ivan zimmermann" src={Imagenes.farmacity2}/>
            </Grid>
            <Grid item xs={12}>
                <Img alt="ivan zimmermann" src={Imagenes.farmacity3}/>
            </Grid>
            <Grid item xs={12}>
                <Img alt="ivan zimmermann" src={Imagenes.farmacity4}/>
            </Grid>
        </Grid>
        <ThemeProvider theme={ApercuPro}>
            <CssBaseline />
            <Box pt={3}>
                <Stack 
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.totalmedios.com/nota/17657/la-salud-tambien-se-contagia-la-campana-de-y-r-para-farmacity" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.totalmedios.com/nota/17657/la-salud-tambien-se-contagia-la-campana-de-y-r-para-farmacity'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="http://www.latinspots.com/sp/noticia/farmacity-contagia-salud-con-la-creatividad-de-yr/29189" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'http://www.latinspots.com/sp/noticia/farmacity-contagia-salud-con-la-creatividad-de-yr/29189'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://infonegocios.info/infopublicidad/aparecio-la-primera-campana-de-y-r-para-farmacity" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://infonegocios.info/infopublicidad/aparecio-la-primera-campana-de-y-r-para-farmacity'}
                        </StyledLink>
                        <StyledLink 
                            textTransform="none" 
                            lineHeight= {1} 
                            variant="body2" 
                            href="https://www.dossiernet.com.ar/articulo/pre-estreno-de-la-nueva-campana-de-farmacity-creada-por-young/1034" 
                            underline="hover" 
                            target="_blank" 
                            rel="noopener">
                        {'https://www.dossiernet.com.ar/articulo/pre-estreno-de-la-nueva-campana-de-farmacity-creada-por-young/1034'}
                        </StyledLink>
                </Stack>
            </Box>
        </ThemeProvider>
    </Grid>
  )
}

WorkFarmacity.propTypes = {}

export default WorkFarmacity