import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ReactPlayer from 'react-player'
import { RiGridFill } from 'react-icons/ri'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ApercuPro from '../ApercuPro.jsx'
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)`
    color: inherit;
    &:hover {
    background: black;
    color: white;
    };
    `
const WorkAbinbev = props => {
  return (
    <Grid p={3}
    container
    direction="column"
    justifyContent="center"
    >
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
            <Link color="inherit"
                aria-label="menu"
                component={RouterLink}
                to={'/works'}>
                <RiGridFill fontSize={30} />
            </Link>
        </Grid>
        <Grid container pt={3}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                <Typography variant='h4'>
                    AB INBEV: Hairy
                </Typography>
                <Typography variant='h5' py={3}>
                    When Ab InBev created a lovely imaginary character hated by a whole country.
                </Typography>
                <Typography variant='body2' pb={1}>
                    In Argentina, due to constant inflation, it’s more and more common to hear that people don’t have money at the end of the month. The brief was actually the launch of a discount platform (“Second half”) which is activated in the second half of each month. And the challenge was how to communicate this without feeling like another promo and talking to the audience in a sense they can relate to. That's when "Peludo" (Hairy) came out, which in Argentina we all use to refer to an unfavorable situation, as in this case, not being able to buy the products and brands we want. “Hairy” was born, a character that deprives you of being able to buy everything you want, until now.
                </Typography>               
                <Typography variant='body2'>
                    Creative Agency: Draftline BUE / Ivan Zimmermann (Freelance Creative)
                </Typography>
                <Typography variant='body2'pb={3}>
                    Production Company: Landia / Dir. Francisco Colombatti
                </Typography>
            </ThemeProvider>
        </Grid>
        <Grid container className='player-wrapper' pb={2}>
            <ReactPlayer
                url= 'https://vimeo.com/763514289'
                className='react-playeri'          
                controls
                width='100%'
                height='100%'
                />
        </Grid>
        <Grid container pt={2}>
            <ThemeProvider theme={ApercuPro}>
                <CssBaseline />
                    <StyledLink py={0.5}
                        textTransform="none" 
                        lineHeight= {1} 
                        variant="body2" 
                        href="https://www.lbbonline.com/news/drafline-bues-2nd-half-platform-brings-a-happy-ending-to-the-month" 
                        underline="hover" 
                        target="_blank" 
                        rel="noopener">
                    {'https://www.lbbonline.com/news/drafline-bues-2nd-half-platform-brings-a-happy-ending-to-the-month'}
                    </StyledLink>
                    <StyledLink py={0.5}
                        textTransform="none" 
                        lineHeight= {1} 
                        variant="body2" 
                        href="https://www.adlatina.com/publicidad/preestreno-cerveceria-y-malteria-quilmes-draftline-y-una-plataforma-para-cuando-esta-peludo" 
                        underline="hover" 
                        target="_blank" 
                        rel="noopener">
                    {'https://www.adlatina.com/publicidad/preestreno-cerveceria-y-malteria-quilmes-draftline-y-una-plataforma-para-cuando-esta-peludo'}
                    </StyledLink>
                    <StyledLink py={0.5}
                        textTransform="none" 
                        lineHeight= {1} 
                        variant="body2" 
                        href="https://www.latinspots.com/sp/noticia/draftline-bue-y-quilmes-presentan-peludo/63214" 
                        underline="hover" 
                        target="_blank" 
                        rel="noopener">
                    {'https://www.latinspots.com/sp/noticia/draftline-bue-y-quilmes-presentan-peludo/63214'}
                    </StyledLink>
            </ThemeProvider>
        </Grid>
    </Grid>
    )
}

WorkAbinbev.propTypes = {}

export default WorkAbinbev